import {ElementRef, Injectable} from '@angular/core';
import {Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class TooltipService {
    private closeAllTooltipsSubject = new Subject<ElementRef>()
    public closeAllTooltips$ = this.closeAllTooltipsSubject.asObservable()

    closeAllTooltips(initiator: ElementRef) {
        this.closeAllTooltipsSubject.next(initiator)
    }
}
