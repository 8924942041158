import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";
import {SaveBtnState} from "@atl/lacerta-ui-common";
import {HeaderLayoutService} from "@atl/modules/layouts/header-layout/header-layout.service";

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    private saveBtnSubject = new Subject()
    public saveBtn$ = this.saveBtnSubject.asObservable()

    private isSaveBtnDisabledSubject = new BehaviorSubject<boolean>(true)
    public isSaveBtnDisabled$ = this.isSaveBtnDisabledSubject.asObservable()

    private saveBtnStateSubject = new BehaviorSubject<SaveBtnState>('SAVE')
    public saveBtnState$ = this.saveBtnStateSubject.asObservable()

    private isLoadingSubject = new BehaviorSubject<boolean>(false)
    public isLoading$ = this.isLoadingSubject.asObservable()

    constructor(private headerLayoutService: HeaderLayoutService) {
    }

    public setSaveBtnState(state: SaveBtnState) {
        this.saveBtnStateSubject.next(state)
    }

    public setSaveBtnStatus(isDisabled: boolean) {
        this.isSaveBtnDisabledSubject.next(isDisabled)
    }

    public setLoading(isLoading: boolean) {
        this.headerLayoutService.setLoadingProgressBar(isLoading)
        this.isLoadingSubject.next(isLoading)
    }

    public pressSave() {
        this.saveBtnSubject.next(null)
    }
}
