import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs'
import {tap} from 'rxjs/operators'
import {sortByField} from "@atl/shared/utils";
import {
    EventClassesHttpService,
    EventClassesSorting,
    IEventClass,
    IEventClassesFilter,
    IEventClassSound,
    IEventClassTag,
    IGetEventClassesResponse,
    NewEventClassTag
} from "@atl/lacerta-ui-common";
import {FormBuilder, FormControl} from "@angular/forms";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Injectable()
export class EventClassesService {
    public eventClassesMap: Map<number, IEventClass> = new Map<number, IEventClass>()
    public eventClassTagsMap: Map<number, IEventClassTag> = new Map<number, IEventClassTag>()
    public eventSoundsMap: Map<number, IEventClassSound> = new Map<number, IEventClassSound>()
    private eventClassesSubject: BehaviorSubject<IEventClass[]> = new BehaviorSubject<IEventClass[]>([]);
    public eventClasses$: Observable<IEventClass[]> = this.eventClassesSubject.asObservable()
    private allEventClassesSubject: BehaviorSubject<IEventClass[]> = new BehaviorSubject<IEventClass[]>([]);
    public allEventClasses$: Observable<IEventClass[]> = this.allEventClassesSubject.asObservable()
    private activeEventClassSubject: BehaviorSubject<IEventClass> = new BehaviorSubject<IEventClass>(null);
    public activeEventClass$: Observable<IEventClass> = this.activeEventClassSubject.asObservable()
    private eventClassTagsSubject: BehaviorSubject<IEventClassTag[]> = new BehaviorSubject<IEventClass[]>([]);
    public eventClassTags$: Observable<IEventClassTag[]> = this.eventClassTagsSubject.asObservable()
    private eventClassSoundsSubject: BehaviorSubject<IEventClassSound[]> = new BehaviorSubject<IEventClassSound[]>([]);
    public eventClassSounds$: Observable<IEventClassSound[]> = this.eventClassSoundsSubject.asObservable()
    private totalSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0)
    public total$: Observable<number> = this.totalSubject.asObservable()
    private filterForm = this.fb.group({
        checkAndSound: {
            filter_check: null,
            filter_sound: null,
        },
        filter_str: this.fb.control<string>(''),
        pagination: this.fb.group({
            count: this.fb.control<number>(0),
            from: this.fb.control<number>(0),
        }),
        sorting: this.fb.control<EventClassesSorting[]>(null),
    })
    public filterChange$ = this.filterForm.valueChanges
    private lastRequestFilter: IEventClassesFilter = null
    private readonly SESSION_STORAGE_KEY: string = 'SESSION_STORAGE_EVENT_CLASSES_KEY';
    private sessionStorageInitiated = false

    constructor(private eventClassesHttp: EventClassesHttpService, private fb: FormBuilder) {
    }

    public get filterCheckAndSoundForm(): FormControl {
        return this.filterForm.get('checkAndSound') as FormControl
    }

    public get currentPage() {
        return this.fromFormControl.value / this.limit + 1
    }

    public get fromFormControl() {
        return this.filterForm.get('pagination.from') as FormControl
    }

    public get activeSorting() {
        return this.sortingFormControl.value
    }

    public get filterFormControl(): FormControl {
        return this.filterForm.get('checkAndSound') as FormControl
    }

    public get limit() {
        return this.isResponsiveCount ? this.responsiveCount : this.countFormControl.value
    }

    private _responsiveCount: number = 0

    public get responsiveCount(): number {
        return this._responsiveCount
    }

    public set responsiveCount(value: number) {
        if (this._responsiveCount === value) return;
        this._responsiveCount = value
    }

    public get isResponsiveCount(): boolean {
        return !this.countFormControl.value
    }

    public get countFormControl() {
        return this.filterForm.get('pagination.count') as FormControl
    }

    public get sortingFormControl() {
        return this.filterForm.get('sorting') as FormControl
    }

    public initSessionStorage() {
        if (this.sessionStorageInitiated) return
        const settings = this.getSettingsFromSessionStorage()
        if (settings) {
            this.filterForm.patchValue(settings)
        }

        this.filterChange$
            .pipe(untilDestroyed(this))
            .subscribe(v => {
                const settings = structuredClone(v)
                delete settings.pagination
                this.setSettingsToSessionStorage(settings)
            })

        this.sessionStorageInitiated = true
    }

    public getAllEventClasses() {
        return this.eventClassesHttp
            .getEventClasses({}).pipe(
                tap((response: IGetEventClassesResponse) => {
                    this.allEventClassesSubject.next(response.event_classes)
                })
            )
    }

    public setFilterString(str: string) {
        if (this.filterForm.get('filter_str').value === str) return
        this.filterForm.get('filter_str').patchValue(str)
    }

    public changePage(page: number) {
        this.fromFormControl.patchValue((page * this.limit) - this.limit)
    }

    public getById(id: number): IEventClass {
        return this.eventClassesMap.get(id)
    }

    public getSoundById(id: number): string {
        return this.eventSoundsMap.get(id)?.sound
    }

    public getEventClasses(useFormFilter = false, filter?: IEventClassesFilter): Observable<IGetEventClassesResponse> {
        if (useFormFilter) {
            this.resetPageIfNeeded()
            this.lastRequestFilter = this.getFiltersFormValue()
        }
        return this.eventClassesHttp
            .getEventClasses(useFormFilter ? this.lastRequestFilter : filter || {})
            .pipe(tap(res => {
                this.eventClassesSubject.next(res.event_classes || [])
                this.totalSubject.next(res.total)
                res.event_classes?.forEach(value => {
                    this.eventClassesMap.set(value.id, value)
                })
            }))
    }

    public changeSort(sort: EventClassesSorting) {
        const sortValue = this.filterForm.get('sorting').value || []
        if (sortValue.includes(sort)) {
            this.sortingFormControl.patchValue(sortValue.filter(v => v !== sort))
            return
        }
        this.sortingFormControl.patchValue([...sortValue, sort])
    }

    public getFiltersFormValue(): IEventClassesFilter {
        return {
            ...this.filterCheckAndSoundForm.value,
            count: this.limit,
            from: this.fromFormControl.value,
            filter_str: this.filterForm.get('filter_str').value,
            sorting: this.sortingFormControl.value
        }
    }

    public getEventClassesTags(): Observable<IEventClassTag[]> {
        return this.eventClassesHttp
            .getAllEventClassesTags()
            .pipe(tap(eventClassTags => {
                this.eventClassTagsSubject.next(eventClassTags)

                eventClassTags.forEach(value => {
                    this.eventClassTagsMap.set(value.id, value)
                })
            }))
    }

    public getEventClassSounds(): Observable<IEventClassSound[]> {
        return this.eventClassesHttp
            .getAllEventClassSounds()
            .pipe(tap(sounds => {
                this.eventClassSoundsSubject.next(sounds)
                sounds.forEach(value => {
                    this.eventSoundsMap.set(value.id, value)
                })

            }))
    }

    public createEventClassSound(eventClassSound: IEventClassSound): Observable<IEventClassSound> {
        return this.eventClassesHttp.createEventClassSound(eventClassSound)
            .pipe(
                tap(sound => {
                    this.eventClassSoundsSubject.next(sortByField([...this.eventClassSoundsSubject.value, sound], (item) => item.name.toLowerCase()))
                })
            )
    }

    public updateEventClassSound(eventClassSound: Partial<IEventClassSound>, id: number): Observable<IEventClassSound> {
        return this.eventClassesHttp.updateEventClassSound(eventClassSound, id)
            .pipe(
                tap(eventClassSound => {
                    this.eventClassSoundsSubject.next(this.eventClassSoundsSubject.value.map(v => v.id === eventClassSound.id ? eventClassSound : v))
                })
            )
    }

    public deleteEventClassSound(id: number) {
        return this.eventClassesHttp.deleteEventClassSound(id).pipe(tap(() => {
            this.eventClassSoundsSubject.next(this.eventClassSoundsSubject.value.filter(v => v.id !== id))
        }))
    }

    public createEventClassTag(eventClassTag: NewEventClassTag): Observable<IEventClassTag> {
        return this.eventClassesHttp.createEventClassTag(eventClassTag)
            .pipe(
                tap(eventClassTag => {
                    this.eventClassTagsSubject.next(sortByField([...this.eventClassTagsSubject.value, eventClassTag], (item) => item.name.toLowerCase()))
                })
            )
    }

    public updateEventClassTag(eventClassTag: Partial<IEventClassTag>): Observable<IEventClassTag> {
        return this.eventClassesHttp.updateEventClassTag(eventClassTag, eventClassTag.id)
            .pipe(
                tap(eventClassTag => {
                    this.eventClassTagsSubject.next(this.eventClassTagsSubject.value.map(v => v.id === eventClassTag.id ? eventClassTag : v))
                })
            )
    }

    public deleteEventClassTag(eventClassTag: IEventClassTag) {
        return this.eventClassesHttp.deleteEventClassTag(eventClassTag.id).pipe(tap(() => {
            if (this.activeEventClassSubject.value?.event_class_tags?.some(t => t.id === eventClassTag.id)) {
                const active = this.activeEventClassSubject.value
                active.event_class_tags = active.event_class_tags.filter(t => t.id !== eventClassTag.id)
                this.activeEventClassSubject.next(active)
            }
            this.eventClassTagsSubject.next(this.eventClassTagsSubject.value.filter(v => v.id !== eventClassTag.id))
        }))
    }

    public getEventClassById(id: number): Observable<IEventClass> {
        return this.eventClassesHttp.getEventClassById(id)
    }

    public setActiveEventClass(eventClass: IEventClass) {
        this.activeEventClassSubject.next(eventClass)
    }

    public addBlankEventClass(): void {
        this.activeEventClassSubject.next({
            id: null,
            can_check: null,
            lacerta_system: null,
            color_background: '#ffffff',
            color_background_checked: '#ffffff',
            color_text: '#ffffff',
            color_text_checked: '#ffffff',
            name: null,
            priority: null,
            event_class_tags: []
        })
    }

    public createEventClass(eventClass: IEventClass): Observable<IEventClass> {
        return this.eventClassesHttp.createEventClass(eventClass)
            .pipe(
                tap(eventClass => {
                    this.activeEventClassSubject.next(eventClass)
                    this.eventClassesSubject.next([...this.eventClassesSubject.value, eventClass])
                    this.eventClassesMap.set(eventClass.id, eventClass)
                })
            )
    }

    public updateEventClass(eventClass: Partial<IEventClass>): Observable<IEventClass> {
        return this.eventClassesHttp.updateEventClass(eventClass, eventClass.id)
            .pipe(
                tap(eventClass => {
                    if (eventClass.event_class_sound?.id) eventClass.event_class_sound = this.eventSoundsMap.get(eventClass.event_class_sound.id)
                    this.activeEventClassSubject.next(eventClass)
                    this.eventClassesSubject.next(this.eventClassesSubject.value.map(v => v.id === eventClass.id ? eventClass : v))
                    this.eventClassesMap.set(eventClass.id, eventClass)
                })
            )
    }

    public deleteEventClass(eventClass: IEventClass) {
        return this.eventClassesHttp.deleteEventClass(eventClass.id).pipe(tap(() => {
            this.activeEventClassSubject.next(null)
            this.eventClassesSubject.next(this.eventClassesSubject.value.filter(v => v.id !== eventClass.id))
            this.eventClassesMap.delete(eventClass.id)
        }))
    }

    private getSettingsFromSessionStorage() {
        return JSON.parse(sessionStorage.getItem(this.SESSION_STORAGE_KEY))
    }

    private setSettingsToSessionStorage(settings) {
        sessionStorage.setItem(this.SESSION_STORAGE_KEY, JSON.stringify(settings))
    }

    private resetPageIfNeeded() {
        if (!this.lastRequestFilter) return
        const countHasChanged = this.countFormControl.value === 0 ? this.responsiveCount !== this.lastRequestFilter.count : this.countFormControl.value !== this.lastRequestFilter.count
        const filterCheckHasChanged = this.filterCheckAndSoundForm.value.filter_check !== this.lastRequestFilter.filter_check
        const filterSoundHasChanged = this.filterCheckAndSoundForm.value.filter_sound !== this.lastRequestFilter.filter_sound

        if (countHasChanged || filterCheckHasChanged || filterSoundHasChanged) {
            this.fromFormControl.patchValue(0, {emitEvent: false})
        }
    }
}
