import {BaseHttp} from "@atl/lacerta-ui-common";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {HmiSettings} from "@atl/admin/settings/interfaces/hmi-settings.interface";

@Injectable({
    providedIn: 'root'
})
export class HmiSettingsHttpService extends BaseHttp {
    constructor(private http: HttpClient) {
        super();
    }

    getHmiSettings(): Observable<HmiSettings> {
        const url = `${this.apiRoot}/hmi/settings`;
        return this.http.get<HmiSettings>(url, {withCredentials: true});
    }

    setHmiSettings(settings: HmiSettings): Observable<HmiSettings> {
        const url = `${this.apiRoot}/hmi/settings`;
        return this.http.put<HmiSettings>(url, settings, {withCredentials: true});
    }
}
