import {ChangeDetectionStrategy, Component, HostListener, Input} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {IObject} from "@atl/lacerta-ui-common";
import {ObjectsService} from "@atl/admin/objects/services";

@UntilDestroy()
@Component({
    selector: 'lta-tree-bind-object-item',
    templateUrl: 'tree-bind-object-item.component.html',
    styleUrls: ['tree-bind-object-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeBindObjectItemComponent {

    @Input() item: IObject;

    constructor(public objectsService: ObjectsService) {
    }

    @HostListener('click', ['$event']) onClick($event: MouseEvent) {
        $event.stopPropagation()
        this.item.origin_object ? this.objectsService.unbindLinkEvent(this.item) : this.objectsService.bindLinkEvent(this.item);
    }

}

