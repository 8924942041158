import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {HmiSettingsHttpService} from "@atl/admin/settings/services/hmi-settings-http.service";
import {HmiSettings} from "@atl/admin/settings/interfaces/hmi-settings.interface";

@Injectable()
export class HmiSettingsService {

    private hmiSettingsSubject = new BehaviorSubject<HmiSettings>(null)
    public hmiSettings$ = this.hmiSettingsSubject.asObservable()


    constructor(private hmiSettingsHttpService: HmiSettingsHttpService) {
    }

    public getHmiSettings(): Observable<HmiSettings> {
        return this.hmiSettingsHttpService.getHmiSettings()
            .pipe(
                tap(value => {
                    this.hmiSettingsSubject.next(value)
                })
            )
    }

    public saveHmiSettings(settings: HmiSettings): Observable<HmiSettings> {
        return this.hmiSettingsHttpService.setHmiSettings(settings)
            .pipe(
                tap(value => {
                    this.hmiSettingsSubject.next(value)
                })
            )
    }
}
