<div
        [style.top]="top + 'px'"
        class="progress-bar-container"
>
    <div class="progress-bar"></div>
</div>
<div
        [class]="background"
        [style.height]="'calc(100% - ' + bottomOffset + 'px)'"
></div>
