import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostListener,
    Inject,
    Input,
    ViewChild,
} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy'
import {UrlService} from "@atl/shared/services/url.service";
import {ADMIN_TREE, SETTINGS_TREE} from "@atl/shared/interfaces/url.interfaces";
import {switchMap} from "rxjs/operators";
import {HmiService} from '@app/@atl/administration/hmi/services';
import {updateTextSize} from "auto-text-size";
import {TranslateService} from "@ngx-translate/core";
import {AUTH_SERVICE, AuthService, USER_SERVICE, UserService} from "@atl/lacerta-ui-common";
import {ModalService} from "@atl/shared/services/modal.service";
import {ObjectSearchModalComponent} from "@atl/modules/modals/object-search-modal/object-search-modal.component";
import {RaisedEventsService} from "@atl/shared/services/raised-events.service";
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {FlashMessagesService} from "@atl/modules/flash-messages/services/flash-messages.service";

@UntilDestroy()
@Component({
    selector: 'lta-toolbar',
    templateUrl: 'toolbar.component.html',
    styleUrls: ['toolbar.component.scss'],
    providers: [HmiService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent implements AfterViewInit {
    @ViewChild('titleContainer') titleContainerElement: ElementRef;
    @ViewChild('title') titleElement: ElementRef;
    public user$ = this.userService.user$
    public userPicture$ = this.userService.userPicture$
    public defaultHMI$ = this.urlService.defaultStartScreen$.pipe(switchMap(id => this.hmiService.getVideoScreenById(id)))
    isPopupOpen = false;
    public uncheckedEventsCount$ = this.raisedEventsService.uncheckedEventsCount$
    public hasRaisedEvents$ = this.raisedEventsService.hasRaisedEvents$
    public lastEventColor$ = this.raisedEventsService.lastEventColor$

    constructor(
        private urlService: UrlService,
        @Inject(USER_SERVICE) private userService: UserService,
        @Inject(AUTH_SERVICE) private authService: AuthService,
        private hmiService: HmiService,
        private translateService: TranslateService,
        private modalService: ModalService,
        private raisedEventsService: RaisedEventsService,
        private flashMessagesService: FlashMessagesService
    ) {
    }

    private _title: string

    public get title() {
        return this._title
    }

    @Input() set title(title: string) {
        this._title = title
        if (!this.titleElement) return
        if (!title) return
        this.titleElement.nativeElement.textContent = this.translateService.instant(this.title)
        this.updateTitleSize()
    };

    @HostListener('window:resize')
    onResize() {
        this.updateTitleSize();
    }

    public onSettingsClick() {
        this.urlService.goToSettings({branch: SETTINGS_TREE.main})
        this.isPopupOpen = false;
    }

    public onAdministrationClick() {
        this.urlService.goToAdmin({branch: ADMIN_TREE.objects})
        this.isPopupOpen = false;
    }

    public onDoorClick() {
        this.authService.logout().subscribe()
        this.authService.sendLogoutRequestToOtherTabs()
        this.isPopupOpen = false
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.titleContainerElement.nativeElement.style.maxWidth = `${this.titleContainerElement.nativeElement.getBoundingClientRect().width - 40}px`
            this.titleContainerElement.nativeElement.style.width = `initial`
            this.titleContainerElement.nativeElement.style.display = `block`
            if (!this.title) return
            this.titleElement.nativeElement.textContent = this.translateService.instant(this.title)
            this.updateTitleSize()
        })
    }

    getUrlVideoFrames(id: number) {
        if (!id) return
        return ['/', this.urlService.HMI_ROOT, id]
    }

    getUrlAlerts() {
        return [this.urlService.ALERTS_ROOT]
    }

    getUrlTrends() {
        return [this.urlService.TRENDS_ROOT]
    }

    getUrlTable() {
        return [this.urlService.TABLE_ROOT]
    }

    openSettings(): void {
        this.urlService.goToSettings({branch: SETTINGS_TREE.main})
    }

    getUrlVnc() {
        return [this.urlService.VNC_ROOT]
    }

    onPrint(): void {
        window.print();
    }

    onSnapShot(): void {
        this.saveCapture();
    }

    saveCapture() {
        try {
            const stream = fromPromise(navigator.mediaDevices
                // @ts-ignore
                .getDisplayMedia({video: true, preferCurrentTab: true}
                )
            );
            stream.subscribe(v => {
                const track = v.getVideoTracks()[0];
                // @ts-ignore
                let imageCapture = new ImageCapture(track);

                imageCapture.grabFrame().then(bitmap => {
                    const canvas = document.createElement('canvas');
                    canvas.width = bitmap.width;
                    canvas.height = bitmap.height;
                    const ctx = canvas.getContext('bitmaprenderer');
                    ctx.transferFromImageBitmap(bitmap);
                    const link = document.createElement('a');

                    link.setAttribute('href', canvas.toDataURL('image/png'));
                    link.setAttribute('download', `img.png`);
                    link.setAttribute('target', '_blank');
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    track.stop()

                })

            })
        } catch (e) {
            this.flashMessagesService.showWarning({title: this.translateService.instant('lta.httpsReq')})
        }
    }

    togglePopup() {
        this.isPopupOpen = !this.isPopupOpen;
    }

    @HostListener('document:click', ['$event'])
    onDocumentClick(event: MouseEvent) {
        if (!event.composedPath().includes(document.querySelector('.chevron-down'))) {
            this.isPopupOpen = false
        }
    }

    public onSearch() {
        const modalId = `object-search-modal`
        const deleteDialogRef = this.modalService.create<ObjectSearchModalComponent>(modalId, ObjectSearchModalComponent)

        deleteDialogRef.instance.onCancel.pipe(untilDestroyed(deleteDialogRef.instance)).subscribe(() => {
            this.modalService.remove(modalId)
        })
    }

    private updateTitleSize() {
        updateTextSize({
            innerEl: this.titleElement.nativeElement,
            containerEl: this.titleContainerElement.nativeElement,
            mode: 'oneline',
            maxFontSizePx: 20,
            minFontSizePx: 3
        })
    }
}
