import {Injectable} from '@angular/core'
import {HttpClient, HttpParams} from '@angular/common/http'
import {Observable} from 'rxjs'
import {catchError, map} from 'rxjs/operators'
import {Router} from '@angular/router';

import {BaseHttp, IObject} from '@atl/lacerta-ui-common'
import {
    IUpdateVSInput,
    IVideoScreen,
    IVideoScreenArgument, IVideoScreenArgumentChild,
    IVideoScreenElement, IVideoScreenFolder, IVideoScreenFolderContent, IVideoScreenFoldersTreeNode,
    IVideoScreenRuntime
} from '../interfaces'

@Injectable({
    providedIn: 'root',
})
export class HmiHttpService extends BaseHttp {
    constructor(
        private http: HttpClient,
        private router: Router
    ) {
        super()
    }

    getFolderContentById(id: number, withoutSvg: boolean = false): Observable<IVideoScreenFolderContent> {
        const url = `${this.apiRoot}/hmi/folders/${id}/childs`;
        let params = new HttpParams();
        if (withoutSvg) {
            params = params.append('without_svg', withoutSvg)
        }
        return this.http.get<IVideoScreenFolderContent>(url, {withCredentials: true, params})
    }

    getVideoScreens(withoutSvg: boolean = false): Observable<IVideoScreen[]> {
        const url = `${this.apiRoot}/hmi`
        let params = new HttpParams();
        if (withoutSvg) {
            params = params.append('without_svg', withoutSvg)
        }
        return this.http
            .get<IVideoScreen[]>(url, {withCredentials: true, params})
    }

    getVideoScreenById(id: number): Observable<IVideoScreen> {
        const url = `${this.apiRoot}/hmi/${id}`;
        return this.http
            .get<IVideoScreen>(url, {withCredentials: true})
            .pipe(
                catchError(err => {
                    this.router.navigateByUrl('/not-found');
                    throw err
                })
            );
    }

    getVideoScreenRuntimeById(id: number, args?: number[]): Observable<IVideoScreenRuntime> {
        const url = `${this.apiRoot}/hmi/${id}/runtime`
        return this.http
            .get<IVideoScreenRuntime>(url, {
                withCredentials: true,
                params: args && new HttpParams().set('arguments', args.toString())
            })
            .pipe(
                catchError(err => {
                    if (err.status == 404 && !args) {
                        this.router.navigateByUrl('/not-found');
                    }
                    throw err;
                })
            );
    }

    bindElementToObject(
        element: IVideoScreenElement,
        object: IObject
    ): Observable<IVideoScreenElement> {
        const url = `${this.apiRoot}/hmi/element/${element.id}/bind_object/${object.id}`
        return this.http
            .put<IVideoScreenElement>(url, {}, {withCredentials: true})
            .pipe(
                map(() => ({
                    ...element,
                    object: {id: object.id, name: object.name},
                }))
            )
    }

    bindElementToArgument(
        element: IVideoScreenElement,
        argument: IVideoScreenArgument
    ): Observable<IVideoScreenElement> {
        const url = `${this.apiRoot}/hmi/element/${element.id}/bind_argument/${argument.id}`
        return this.http
            .put<null>(url, {}, {withCredentials: true})
    }

    bindElementToArgumentChild(
        element: IVideoScreenElement,
        argument: IVideoScreenArgumentChild
    ): Observable<IVideoScreenElement> {
        const url = `${this.apiRoot}/hmi/element/${element.id}/bind_argument/${argument.argument.id}/${argument.path}`
        return this.http
            .put<null>(url, {}, {withCredentials: true})
    }

    unbindElement(element: IVideoScreenElement): Observable<any> {
        const url = `${this.apiRoot}/hmi/element/${element.id}/unbind`
        return this.http.put<any>(url, {}, {withCredentials: true})
    }

    updateVideoScreenElement(id: number, obj: IVideoScreenElement): Observable<IVideoScreenElement> {
        const url = `${this.apiRoot}/hmi/element/${id}`;
        return this.http.put<IVideoScreenElement>(url, obj, {withCredentials: true});
    }

    updateVideoScreen(id: number, videoScreenOrBody: IVideoScreen | IUpdateVSInput): Observable<IVideoScreen> {
        const url = `${this.apiRoot}/hmi/${id}`;
        return this.http.put<IVideoScreen>(url, videoScreenOrBody, {withCredentials: true});
    }

    addVideoScreenArgument(id: number, body: { model: number }): Observable<IVideoScreenArgument> {
        const url = `${this.apiRoot}/hmi/${id}/arguments`;
        return this.http.post<IVideoScreenArgument>(url, body, {withCredentials: true});
    }

    deleteVideoScreenArgument(id: number, argumentId: number): Observable<null> {
        const url = `${this.apiRoot}/hmi/${id}/arguments/${argumentId}`;
        return this.http.delete<null>(url, {withCredentials: true});
    }

    deleteVideoScreen(id: number): Observable<null> {
        const url = `${this.apiRoot}/hmi/${id}`;
        return this.http.delete<null>(url, {withCredentials: true});
    }

    createVideoScreen(videoScreen: any): Observable<IVideoScreen> {
        const url = `${this.apiRoot}/hmi`;
        return this.http.post<IVideoScreen>(url, videoScreen, {withCredentials: true});
    }

    createFolder(parentId: number, name: string): Observable<IVideoScreenFolder> {
        const url = `${this.apiRoot}/hmi/folders/${parentId}`;
        return this.http.post<IVideoScreenFolder>(url, {name}, {withCredentials: true});
    }

    updateFolder(folder: IVideoScreenFolder): Observable<IVideoScreenFolder> {
        const url = `${this.apiRoot}/hmi/folders/${folder.id}`;
        return this.http.put<IVideoScreenFolder>(url, folder, {withCredentials: true});
    }

    deleteFolder(ids: number[]): Observable<void> {
        let params = new HttpParams();
        params = params.append('ids',ids.join(','))
        const url = `${this.apiRoot}/hmi/folders`;
        return this.http.delete<void>(url, {withCredentials: true, params});
    }

    moveItemsToFolder(folderId: number, items: { folders: number[], hmi: number[] }): Observable<void> {
        const url = `${this.apiRoot}/hmi/folders/${folderId}/add`;
        return this.http.put<void>(url, items, {withCredentials: true});
    }

    getFoldersTree(rootId: number): Observable<IVideoScreenFoldersTreeNode> {
        const url = `${this.apiRoot}/hmi/folders/${rootId}/tree`;
        return this.http.get<IVideoScreenFoldersTreeNode>(url, {withCredentials: true});
    }
}
