import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {UntilDestroy} from "@ngneat/until-destroy";
import {MainPageService} from "@atl/main/main-page/main-page.service";
import {FlashMessagesService} from "@atl/modules/flash-messages/services/flash-messages.service";

@UntilDestroy()
@Component({
    selector: 'lta-main-page',
    templateUrl: 'main-page.component.html',
    styleUrls: ['main-page.component.scss'],
    providers: [MainPageService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainPageComponent implements OnInit {
    public loaderBottomOffset$ = this.mainPageService.preloaderBottomOffset$

    constructor(
        public mainPageService: MainPageService,
        private flashMessagesService: FlashMessagesService
    ) {
    }

    ngOnInit() {
        this.flashMessagesService.clearMessages()
    }
}
